<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <SearchBar
          ref="searchBar"
          @search="onSearch($event)"
          searchLabel="Search by group name..."
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              class="msaBlue white--text"
              @click="addLabel()"
              v-on="on"
              data-testid="add-label"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add Group</span>
        </v-tooltip>
      </v-col>
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-blur
              class="msaBlue white--text"
              v-on="on"
              @click="exportLabels()"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          <span>Export</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text">
            Groups
            <v-badge
              color="msaBlue"
              bordered
              class="ml-4"
              :content="itemsCount"
              :value="itemsCount"
              offset-y="8"
            >
            </v-badge>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="itemsCount"
            :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
            :options.sync="options"
          >
            <template
              v-slot:body="{ items }"
              v-if="!isSearching || itemsCount > 0"
            >
              <v-slide-y-transition group tag="tbody">
                <template v-for="item in items">
                  <tr :key="item.id">
                    <td>
                      <span
                        :style="{
                          cursor: 'pointer',
                          color: 'rgb(53, 100, 172)',
                        }"
                        @click="goToLabel(item)"
                      >
                        {{ item.name }}
                      </span>
                    </td>
                    <td align="center">
                      {{ item.attachedEmployees }}
                    </td>
                    <td align="center">
                      {{ item.attachedDocuments }}
                    </td>
                    <td align="center">
                      {{ item.attachedCourses }}
                    </td>
                    <td align="center">
                      {{ item.attachedTaskGroups }}
                    </td>
                    <td align="center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-blur
                            color="msaBlue"
                            text
                            small
                            v-on="on"
                            @click="editLabel(item)"
                          >
                            <v-icon> mdi-pencil </v-icon>
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-blur
                            color="red"
                            text
                            small
                            v-on="on"
                            @click="deleteLabel(item.id)"
                          >
                            <v-icon> mdi-delete </v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-slide-y-transition>
            </template>
            <template v-slot:body v-else>
              <tr>
                <td colspan="6" align="center">
                  <v-container>
                    <v-row>
                      <v-col class="grey--text text-body-2">
                        No results found
                      </v-col>
                    </v-row>
                  </v-container>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="labelDetailsDialog" width="500" persistent>
      <v-card>
        <v-card-title class="msaBlue white--text"> Group Details </v-card-title>
        <v-card-text class="mt-4">
          <v-text-field
            v-model="selectedLabel.name"
            dense
            counter
            maxlength="1000"
            outlined
            label="Name"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="closeLabelDetailsDialog()">
            cancel
          </v-btn>
          <v-btn
            @click="confirmLabelDetails()"
            class="msaBlue white--text"
            :disabled="disableSave"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
export default {
  name: 'CompanyGroupsPage',
  components: {
    SearchBar,
  },
  watch: {
    options: {
      handler(oldVal, newVal) {
        const changed = Object.keys(newVal).some(
          (key) => newVal[key].toString() != oldVal[key].toString(),
        );

        if (changed) {
          this.updateUrl();
        }
      },
      deep: true,
    },
  },
  computed: {
    disableSave() {
      if (this.selectedLabel.id == undefined) {
        return this.selectedLabel.name.trim().length == 0;
      } else {
        const label = this.items.find((i) => i.id == this.selectedLabel.id);
        return (
          this.selectedLabel.name.trim().length == 0 ||
          this.selectedLabel.name.trim() == label.name
        );
      }
    },
    isSearching() {
      return this.search.trim().length > 0;
    },
  },
  data() {
    return {
      search: '',
      items: [],
      itemsCount: 0,
      options: {
        sortBy: ['name'],
        sortDesc: [false],
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      },
      headers: [
        {
          text: 'Name',
          value: 'name',
          align: 'start',
          class: 'lightGrey',
        },
        {
          text: 'Attached Users',
          value: 'attachedEmployees',
          align: 'center',
          class: 'lightGrey',
          width: '170px',
        },
        {
          text: 'Attached Documents',
          value: 'attachedDocuments',
          align: 'center',
          class: 'lightGrey',
          width: '170px',
        },
        {
          text: 'Attached Courses',
          value: 'attachedCourses',
          align: 'center',
          class: 'lightGrey',
          width: '150px',
        },
        {
          text: 'Attached Task Groups',
          value: 'attachedTaskGroups',
          align: 'center',
          class: 'lightGrey',
          width: '170px',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          class: 'lightGrey',
          sortable: false,
          width: '140px',
        },
      ],
      labelDetailsDialog: false,
      selectedLabel: { name: '' },
    };
  },
  methods: {
    getItems() {
      const params = {
        search: this.search ?? '',
        options: this.options,
        loaderText: 'Loading...',
      };

      this.$axios
        .post('get-company-specific-labels?format=json', params)
        .then((response) => {
          this.items = response.data.items;
          this.itemsCount = response.data.count;
        })
        .catch((error) => error);
    },
    onSearch(event) {
      this.search = event;
      this.options.page = 1;
      this.updateUrl();
    },
    updateUrl() {
      const params = this.$helpers.serializeOptions(
        this.options,
        'name',
        false,
      );

      if (this.search != '') {
        params.search = this.search;
      }

      this.$router
        .replace({ name: this.$route.name, query: params })
        .catch(() => {});
    },
    getRouteQueries() {
      const query = this.$route.query;
      this.options = this.$helpers.deserializeOptions(query, 'name', false);

      this.search = this.$helpers.getRouteQueryForString(
        this.$route.query,
        'search',
      );

      this.getItems();
    },
    addLabel() {
      this.selectedLabel = { name: '' };
      this.labelDetailsDialog = true;
    },
    editLabel(label) {
      this.selectedLabel = { ...label };
      this.labelDetailsDialog = true;
    },
    closeLabelDetailsDialog() {
      this.selectedLabel = { name: '' };
      this.labelDetailsDialog = false;
    },
    confirmLabelDetails() {
      const isNewLabel = this.selectedLabel.id == undefined;

      const params = {
        loaderText: 'Loading...',
        label: this.selectedLabel,
      };
      const url = isNewLabel
        ? 'add-company-specific-label?format=json'
        : 'update-company-specific-label?format=json';
      this.$axios
        .post(url, params)
        .then(() => {
          this.closeLabelDetailsDialog();
          this.getItems();
        })
        .catch((error) => error);
    },
    exportLabels() {
      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };
      const params = {
        loaderText: 'Loading...',
        search: this.search,
        options: this.options,
      };
      const url = 'export-company-specific-labels?format=json';
      this.$axios
        .post(url, params, options)
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Group Export.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => error);
    },
    deleteLabel(labelId) {
      const confirm = () => {
        const params = {
          loaderText: 'Deleting labels...',
          id: labelId,
        };
        const url = 'delete-company-specific-label?format=json';
        this.$axios
          .post(url, params)
          .then(() => {
            this.getItems();
          })
          .catch((error) => error);
      };
      this.$root.showMessage(
        'Confirm',
        'Are you sure? This is irreversible.',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );
    },
    goToLabel(label) {
      this.$store.commit('updateSelectedLabel', label);
      if (this.$route.name == 'CompanyGroups') {
        this.$router.push({
          name: 'CompanyGroupedEmployees',
          params: { id: label.id },
        });
      } else {
        this.$router.push({
          name: 'CompanyTrainingGroupedEmployees',
          params: { id: label.id },
        });
      }
    },
  },
  mounted() {
    this.getRouteQueries();
    this.$nextTick(() => {
      if (this.search != '') {
        this.$refs.searchBar.setSearch(this.search);
      }
    });
  },
};
</script>
